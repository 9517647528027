import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import moment, { Moment } from "moment";

export const getKPIs = async (
  start_date: moment.Moment = moment(),
  end_date: moment.Moment = moment()
): Promise<{ [key: string]: number }> => {
  const data = {
    start_date: start_date.format("YYYY-MM-DD"),
    end_date: end_date.format("YYYY-MM-DD"),
  };
  const config: AxiosRequestConfig = {
    method: "GET",
    url: "http://35.200.133.32:5000/api/v1.1/statistics/kpi",
    headers: {
      "Content-Type": "application/json",
    },
    params: data,
  };

  const response = await axios(config);
  console.log(response);
  return response.data.kpi;
};

export interface IGetStatisticalKPIs {
  status: string;
  kpi: Record<string, number>;
}

export const getStatisticalKPIs = async (
  {
    token,
    start_date,
    end_date,
  }: Record<"token" | "start_date" | "end_date", string> = {
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    token: "",
  }
): Promise<AxiosResponse<IGetStatisticalKPIs>> => {
  const data = {
    start_date: start_date,
    end_date: end_date,
  };
  const config: AxiosRequestConfig = {
    method: "GET",
    url: "https://api.sht.distronix.in:4443/api/v1.1/statistics/kpi",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
    params: data,
  };
  const response: AxiosResponse<IGetStatisticalKPIs> = await axios(config);
  // console.log("GetAllKPIs: ", response.data.kpi);
  return response;
};

export const KPIs: Record<string, string> = {
  total_tags: "total_tags",
  total_cleaned: "total_cleaned",
  total_uncleaned: "total_uncleaned",
  total_scaned_tags: "total_scaned_tags",
  scaned_by_swm: "scaned_by_swm",
  scaned_by_mosquito: "scaned_by_mosquito",
  scaned_by_electrical: "scaned_by_electrical",
  total_segregate_weast: "total_segregate_weast",
};
