import React, { useCallback, useEffect, useState } from "react";
import { Header, SideBar } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { logout, RootState, store, useAppDispatch } from "../../redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

import { UserRoutes } from "./route";

import axios, { AxiosRequestConfig } from "axios";
import { dispatch } from "d3-dispatch";
import { Tab, Tabs } from "react-bootstrap";
import Account from "../../components/Account/addUser";
import AddUser from "../../components/Account/addUser";
import UpdateUser from "../../components/Account/updateUser";
import { Logout } from "../../api/login/logout";
import { UserActivity } from "../../api/userActivity";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

export const UserLayout = () => {
  const LayoutPath = "/user";
  const user = useSelector((state: RootState) => state.user);
  const flag = user.loggedIn;
  const refreshToken = useSelector(
    (state: RootState) => state.user.refreshToken
  );
  const dispatch = useAppDispatch();

  const responseApi = (RefreshToken: string) => {
    let config: AxiosRequestConfig = {
      method: "post",
      url: "https://api.sht.distronix.in:4443/api/users/userSessionCheck",
      headers: {
        "x-access-refresh-token": RefreshToken,
      },
    };

    axios(config)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log("dispatching logout...");
        dispatch(logout());
        // dispatch(logout())
        console.log(error.message);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (flag) {
        responseApi(refreshToken);
      }
    }, 3 * 10 * 1000);
    return () => clearInterval(interval);
  }, []);

  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getRoutes = () => {
    return UserRoutes.map((item, key) => (
      <Route path={LayoutPath + item.path} key={key}>
        <item.Component />
      </Route>
    ));
  };

  if (!flag) return <Redirect to="/guest/signin" />;

  return (
    <>
      <div
        className="App"
        style={{
          flex: 1,
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header name="WBPCB Smart Home Tags" logoURL="/WBPCB_fulllogo.png" />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
          }}
        >
          <SideBar
            UserAvatar={user.profilePic}
            UserName={user.name}
            UserDesignation={user.userRole}
            openAccount={setOpen}
          />
          <Switch>
            {getRoutes()}
            <Route path="/">
              <Redirect to="/user/dashboard" />
            </Route>
          </Switch>
        </div>
      </div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{ marginLeft: "30%", marginTop: "10%" }}
          className={classes.paper}
        >
          <Tabs
            defaultActiveKey="Add New User"
            id="uncontrolled-tab-example"
            style={{ display: "flex", justifyContent: "center", fontSize: 16 }}
          >
            <Tab eventKey="Add New User" title="Add New User">
              <AddUser closeView={handleClose} />
            </Tab>
            <Tab eventKey="Update Account" title="Update Account">
              <UpdateUser closeView={handleClose} />
            </Tab>
          </Tabs>
        </div>
      </Modal>
    </>
  );
};
