import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export const GetAllTagsDatewise = async ({
  token,
  startDate,
  endDate,
}: Record<"token" | "startDate" | "endDate", string>): Promise<
  AxiosResponse<any>
> => {
  var config: AxiosRequestConfig = {
    method: "get",
    url: "https://api.sht.distronix.in:4443/api/v1.1/statistics/TotalbinsDate",
    headers: {
      "x-access-token": token,
    },
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  };

  // const result2 = ApiHandler({ config: config, retry: 0 })
  // const result = await axios(config)
  //   .then((res) => ({ data: res.data, success: true, error: "" }))
  //   .catch((e) => ({ error: e, success: false, data: [] }));
  // console.log(result);
  // return result;
  const response: AxiosResponse<any> = await axios(config);
  // console.log("GetAllTagsDatewise: ", response.data)
  return response;
};
