import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Page,
  Inject,
  Filter,
  Toolbar,
  PdfExport,
  ExcelExport,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { PdfTrueTypeFont } from "@syncfusion/ej2-pdf-export";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState, Tag, useAppDispatch } from "../../redux";
import { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { Form } from "react-bootstrap";
import { GetPremiseWise } from "../../api/getPremiseWise";
import { callAPI } from "../../api/callAPI";
import { GetAllTagsDatewise } from "../../api/tags/getAllTagsDatewise";
import { GetBlockWiseBinsData } from "../../api";
import { DateRange } from "../../pages/Reports/DateRange2";

const BlockWiseDatagrid: React.FC<{
  blockNumber: string;
  startDateTime: Date | null;
  endDateTime: Date | null;
  closeView: () => void;
}> = ({ blockNumber, startDateTime, endDateTime, closeView }) => {
  const { tags } = useSelector((state: RootState) => state.tags);

  const [Block, setBlock] = useState<any[]>([]);
  const [SelectedBlock, setSelectedBlock] = useState<any[]>([
    {
      label: blockNumber,
      value: blockNumber,
    },
  ]);
  const { token, refreshToken } = useSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();
  const [GridData, setGridData] = useState<any[]>([]);
  const [startDate, setStartDate] = useState(
    moment(startDateTime).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(endDateTime).format("YYYY-MM-DD")
  );
  const setDates = (s: string, e: string) => {
    setStartDate(s);
    setEndDate(e);
  };

  const filterOption = {
    ignoreAccent: true,
    type: "Excel",
  };

  const toolbarOptions = ["Search"];
  const toolbarClick = (args: any) => {
    // closeView();
  };

  let grid: any;

  const GetAndStoreTags = async () => {
    tags?.map((t) =>
      setBlock((d) => [
        ...d.filter((i) => i.label !== t.block),
        { label: t.block, value: t.block },
      ])
    );
  };

  useEffect(() => {
    GetAndStoreTags();
  }, [tags]);

  useEffect(() => {
    const setAPI = async () => {
      const bins = await GetBlockWiseBinsData(
        token,
        refreshToken,
        startDate,
        endDate,
        dispatch
      );
      const sortedBins = bins.sort((pre: any, next: any) => {
        return pre.Block === next.Block ? 0 : pre.Block > next.Block ? 1 : -1;
      });
      // const nsortedBins = sortedBins.map((item: any, index: any) => ({
      //   ...item,
      //   sl_no: index + 1,
      // }));

      // const filterData = SelectedBlock.map((i: any) => {
      //   return sortedBins.find((j: any) => j.Block === i.value);
      // });

      const filterdata2 = sortedBins.filter((item: any) => {
        return item.Block === blockNumber;
      });

      setGridData(filterdata2);
    };
    setAPI();
  }, [startDate, endDate, SelectedBlock]);

  return (
    <div>
      <Form.Group
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        {/* <MultiSelect
          options={Block}
          value={SelectedBlock}
          onChange={setSelectedBlock}
          labelledBy="Select Premise"
        /> */}
        <div style={{ backgroundColor: "#000000" }}>
          <DateRange
            startDate={startDate}
            endDate={endDate}
            setDates={setDates}
          />
        </div>
        <div style={{ width: 20 }} />
      </Form.Group>
      <GridComponent
        dataSource={GridData}
        ref={(g) => (grid = g)}
        height="350"
        width="100%"
        toolbar={toolbarOptions}
        allowPaging={true}
        allowFiltering={true}
        //@ts-ignore
        filterSettings={filterOption}
        toolbarClick={toolbarClick}
        allowSorting={true}
        allowPdfExport={true}
        allowExcelExport={true}
        enableHover={true}
        allowTextWrap={true}
      >
        <ColumnsDirective>
          {/* <ColumnDirective
            field="sl_no"
            headerText="Sl No"
            //@ts-ignore
            textAlign="left"
            width="100"
          /> */}
          <ColumnDirective
            field="Block"
            headerText="Block"
            //@ts-ignore
            textAlign="left"
            width="120"
          />
          <ColumnDirective
            field="ActionArea"
            headerText="Action Area"
            //@ts-ignore
            textAlign="left"
            width="150"
          />

          <ColumnDirective
            field="TotalBins"
            headerText="Total Bins"
            //@ts-ignore
            textAlign="left"
            width="150"
          />
          <ColumnDirective
            field="CleanedBins"
            headerText="CleanedBins"
            //@ts-ignore
            textAlign="left"
            width="170"
          />
          <ColumnDirective
            field="UncleanedBins"
            headerText="Uncleaned Bins"
            //@ts-ignore
            textAlign="left"
            width="170"
          />
          <ColumnDirective
            field="CleanedBinsPercentage"
            headerText="Cleaned Bins %age"
            //@ts-ignore
            textAlign="left"
            width="200"
          />
          <ColumnDirective
            field="UncleanedBinsPercentage"
            headerText="Uncleaned Bins %age"
            //@ts-ignore
            textAlign="left"
            width="200"
          />

          <ColumnDirective
            field="Date"
            headerText="Date"
            //@ts-ignore
            textAlign="left"
            width="150"
          />
        </ColumnsDirective>
        <Inject
          services={[Page, Filter, Sort, Toolbar, PdfExport, ExcelExport]}
        />
      </GridComponent>
    </div>
  );
};

export default BlockWiseDatagrid;
