import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Page,
  Inject,
  Filter,
  Toolbar,
  PdfExport,
  ExcelExport,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { PdfTrueTypeFont } from "@syncfusion/ej2-pdf-export";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState, Tag, useAppDispatch } from "../../redux";
import { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { Form } from "react-bootstrap";
import { GetPremiseWise } from "../../api/getPremiseWise";
import { callAPI } from "../../api/callAPI";
import { GetAllTagsDatewise } from "../../api/tags/getAllTagsDatewise";
import { DateRange } from "../../pages/Reports/DateRange2";

const PremiseWiseDatagrid: React.FC<{
  tagsNumber: string;
  startDateTime: Date | null;
  endDateTime: Date | null;
  closeView: () => void;
}> = ({ tagsNumber, startDateTime, endDateTime, closeView }) => {
  const { tags } = useSelector((state: RootState) => state.tags);

  const [Premises, setPremises] = useState<any[]>([]);
  const [SelectedPremises, setSelectedPremises] = useState<any[]>([
    {
      label: tagsNumber,
      value: tagsNumber,
    },
  ]);
  const { token, refreshToken } = useSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();
  const [GridData, setGridData] = useState<any[]>([]);
  const [startDate, setStartDate] = useState(
    moment(startDateTime).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(endDateTime).format("YYYY-MM-DD")
  );
  const setDates = (s: string, e: string) => {
    setStartDate(s);
    setEndDate(e);
  };

  const filterOption = {
    ignoreAccent: true,
    type: "Excel",
  };

  const toolbarOptions = ["Search"];

  const toolbarClick = (args: any) => {
    if (grid) {
      // closeView();
    }
  };

  let grid: any;

  const GetAndStoreTags = async () => {
    tags?.map((t: any) =>
      setPremises((d) => [
        ...d.filter((i) => i.label !== t.premise_no),
        { label: t.premise_no, value: t.premise_no },
      ])
    );
  };

  useEffect(() => {
    GetAndStoreTags();
  }, [tags]);

  useEffect(() => {
    const setAPI = async () => {
      const response: any = await callAPI<any>(
        GetPremiseWise,
        {
          token: token,
          startDate: startDate,
          endDate: endDate,
          // @ts-ignore
          Premises: SelectedPremises.map((p) => p.value),
        },
        refreshToken,
        dispatch
      );
      const rows: any = await callAPI<any>(
        GetAllTagsDatewise,
        {
          token: token,
          startDate: startDate,
          endDate: endDate,
        },
        refreshToken,
        dispatch
      );
      if (!!response && response?.status === 200) {
        setGridData(response?.data?.data);
      }
      if (response?.message === "Unauthorized access.") {
        const response: any = await callAPI<any>(
          GetPremiseWise,
          {
            token: token,
            startDate: startDate,
            endDate: endDate,
            // @ts-ignore
            Premises: SelectedPremises.map((p) => p.value),
          },
          refreshToken,
          dispatch
        );
        if (!!response && response?.status === 200) {
          setGridData(response?.data?.data);
        }
      }
    };
    setAPI();
  }, [startDate, endDate, SelectedPremises]);

  return (
    <div>
      <Form.Group
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <div style={{ backgroundColor: "#000000" }}>
          <DateRange
            startDate={startDate}
            endDate={endDate}
            setDates={setDates}
          />
        </div>
        <div style={{ width: 20 }} />
        <MultiSelect
          options={Premises}
          value={SelectedPremises}
          onChange={setSelectedPremises}
          labelledBy="Select Premise"
        />
      </Form.Group>
      <GridComponent
        dataSource={GridData}
        ref={(g) => (grid = g)}
        height="350"
        width="100%"
        toolbar={toolbarOptions}
        allowPaging={true}
        allowFiltering={true}
        //@ts-ignore
        filterSettings={filterOption}
        toolbarClick={toolbarClick}
        allowSorting={true}
        allowPdfExport={true}
        allowExcelExport={true}
        enableHover={true}
        allowTextWrap={true}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="premise_no"
            headerText="Premise No"
            //@ts-ignore
            textAlign="left"
            width="150"
          />
          <ColumnDirective
            field="date"
            headerText="Date"
            //@ts-ignore
            textAlign="left"
            width="120"
          />
          <ColumnDirective
            field="time"
            headerText="Time"
            //@ts-ignore
            textAlign="left"
            width="120"
          />
          <ColumnDirective
            field="status"
            headerText="Status"
            //@ts-ignore
            textAlign="left"
            width="120"
          />
        </ColumnsDirective>
        <Inject
          services={[Page, Filter, Sort, Toolbar, PdfExport, ExcelExport]}
        />
      </GridComponent>
    </div>
  );
};

export default PremiseWiseDatagrid;
